import axios from 'axios';
import moment from 'moment';
import React, { useContext, useEffect, useState } from 'react';
import { PING_RESOURCE, POLLING_INTERVAL, TIMEOUT_TIME_MS } from './constants';

const timeout = (time, promise) => {
  return new Promise(function (resolve, reject) {
    setTimeout(() => {
      reject(new Error('Request timed out.'));
    }, time);
    promise.then(resolve, reject);
  });
};

const checkOnlineStatus = async () => {
  // eslint-disable-next-line no-undef
  const controller = new AbortController();
  const { signal } = controller;

  // eslint-disable-next-line no-undef
  if (!navigator.onLine) return navigator.onLine;

  try {
    await timeout(
      TIMEOUT_TIME_MS,
      axios(`${PING_RESOURCE}?t=${moment().format('x')}`, {
        method: 'GET',
        signal
      })
    );
    return true;
  } catch (error) {
    controller.abort();
  }
  return false;
};

const OnlineStatusContext = React.createContext(true);

export const OnlineStatusProvider = ({ children }) => {
  const [onlineStatus, setOnlineStatus] = useState(true);

  const checkStatus = async () => {
    const online = await checkOnlineStatus();
    setOnlineStatus(online);
  };

  useEffect(() => {
    // eslint-disable-next-line no-undef
    window.addEventListener('offline', () => {
      setOnlineStatus(false);
    });

    // Add polling incase of slow connection
    const id = setInterval(() => {
      checkStatus();
    }, POLLING_INTERVAL);

    return () => {
      // eslint-disable-next-line no-undef
      window.removeEventListener('offline', () => {
        setOnlineStatus(false);
      });

      clearInterval(id);
    };
  }, []);

  return (
    <OnlineStatusContext.Provider value={onlineStatus}>
      {children}
    </OnlineStatusContext.Provider>
  );
};

export const useOnlineStatus = () => {
  const store = useContext(OnlineStatusContext);
  return store;
};
