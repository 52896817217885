import { useLazyQuery, useMutation } from '@apollo/client';
import { Button, Tag } from 'antd';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useMedia } from 'react-use';
import { Active, EditButton, Inactive } from '../../../assets/svg';
import {
  BREAKPOINTS,
  defaultDateFormat,
  ROUTES,
  SCROLL_CONST
} from '../../../common/constants';
import CommonCard from '../../../components/CommonCard';
import CommonTable from '../../../components/CommonTable';
import LoaderComponent from '../../../components/LoaderComponent';
import Portal from '../../../components/Portal';
import SearchComponent from '../../../components/SearchComponent';
import DeleteModal from '../DeleteModal';
import { UPDATE_ORGANIZATION } from '../graphql/Mutations';
import { GET_ORGANIZATIONS } from '../graphql/Queries';

const OrganizationTable = () => {
  const history = useHistory();
  const initialUserFilter = {
    sortBy: {
      field: 'updatedAt',
      order: 'DESC'
    }
  };
  const [newdata, setNewdata] = useState([]);
  const [isDisabled, setIsDisabled] = useState(false);
  const [mutationId, setMutationId] = useState('');
  const [showModal, setShowModal] = useState(false);
  const [userFilter, setUserFilter] = useState(initialUserFilter);
  const [title, setTitle] = useState('');
  const [sortedInfo, setSortedInfo] = useState({});
  const [loading, setLoading] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const [scrollFlag, setScrollFlag] = useState(false);
  const limit = 10;
  const initialPaginationValue = {
    total: 0,
    current: 1,
    pageSize: limit
  };
  const navPagination = history?.location?.state?.navPagination;
  const [paginationProp, setPaginationProp] = useState(
    navPagination || initialPaginationValue
  );
  const { tenantId } = useParams();
  const isDesktopViewport = useMedia(`(min-width: ${BREAKPOINTS.desktop}px)`);

  const [updateTenant] = useMutation(UPDATE_ORGANIZATION, {
    onError() {},
    refetchQueries: [
      {
        query: GET_ORGANIZATIONS,
        variables: { filter: { sortBy: { field: 'updatedAt', order: 'DESC' } } }
      }
    ]
  });

  const [fetchOrganizationData] = useLazyQuery(GET_ORGANIZATIONS, {
    variables: {
      filter: {
        tenantId: Number(tenantId),
        sortBy: { field: 'updatedAt', order: 'DESC' }
      }
    },
    fetchPolicy: 'network-only',
    onCompleted: (res) => {
      const tenantsdata = res?.tenantList?.data;
      const pagination = {
        ...paginationProp,
        defaultPageSize: 10,
        total: res?.tenantList?.total
      };
      if (scrollFlag) {
        const datalist = [...newdata, ...tenantsdata];
        setNewdata(datalist);
        setScrollFlag(false);
      } else {
        const datalist = [...tenantsdata];
        setNewdata(datalist);
      }
      setLoading(false);
      setPaginationProp(pagination);
      setHasMore(!!tenantsdata?.length);
    },

    onError() {
      setLoading(false);
    }
  });

  const handleActiveStatus = () => {
    setShowModal(true);
  };

  const ActiveButtons = ({ record }) => {
    if (record?.isDisabled === true) {
      return (
        <Active
          onClick={(e) => {
            setMutationId(record?.id);
            setIsDisabled(true);
            e.stopPropagation();
            handleActiveStatus();
            setTitle('Activate');
          }}
        />
      );
    }
    return (
      <Inactive
        onClick={(e) => {
          setMutationId(record?.id);
          e.stopPropagation();
          setIsDisabled(false);
          handleActiveStatus();
          setTitle('Deactivate');
        }}
      />
    );
  };

  const ChangeStatus = ({ record }) => {
    if (record?.isDisabled === false) {
      return <Tag className="active-tag">Active</Tag>;
    }
    return <Tag className="inactive-tag">Inactive</Tag>;
  };

  const organizationData = async (value) => {
    setUserFilter({ ...userFilter, search: value });
    setPaginationProp(initialPaginationValue);
    fetchOrganizationData({
      variables: {
        filter: {
          ...userFilter,
          search: value,
          skip: 0,
          limit: paginationProp?.pageSize
        }
      }
    });
  };

  const handleScroll = (e) => {
    const { target } = e;
    const { scrollTop, scrollHeight, offsetHeight } = target || {};
    const scrolledToBottom =
      scrollTop + offsetHeight >= scrollHeight - SCROLL_CONST;

    if (scrolledToBottom && hasMore && !loading && !scrollFlag) {
      setScrollFlag(true);
      fetchOrganizationData({
        variables: {
          filter: {
            skip: newdata?.length,
            limit: limit,
            ...userFilter
          }
        }
      });
    }
  };

  useEffect(() => {
    fetchOrganizationData({
      variables: {
        filter: {
          skip: 0,
          limit: limit,
          search: '',
          ...userFilter
        }
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleTableChange = (pagination, paginationFilter, sorter) => {
    const { current } = pagination;
    const skip = (current - 1) * pagination.pageSize;
    setPaginationProp({ ...paginationProp, ...pagination });
    setSortedInfo(sorter);
    if (sorter?.column) {
      setUserFilter({
        ...userFilter,
        sortBy: {
          field: sorter.field,
          order: sorter.order === 'ascend' ? 'ASC' : 'DESC'
        }
      });
      fetchOrganizationData({
        variables: {
          filter: {
            skip,
            limit: pagination?.pageSize,
            ...userFilter,
            sortBy: {
              field: sorter.field,
              order: sorter.order === 'ascend' ? 'ASC' : 'DESC'
            }
          }
        }
      });
    } else {
      setUserFilter({
        ...userFilter,
        sortBy: { field: 'updatedAt', order: 'DESC' }
      });
      fetchOrganizationData({
        variables: {
          filter: {
            skip,
            limit: pagination?.pageSize,
            ...userFilter,
            sortBy: { field: 'updatedAt', order: 'DESC' }
          }
        }
      });
    }
  };

  const columns = [
    {
      title: 'ID',
      key: 'id',
      render: (text, record, index) => {
        return (
          <>
            {(paginationProp?.current - 1) * paginationProp?.pageSize +
              index +
              1}
          </>
        );
      }
    },
    {
      title: 'ORGANIZATION NAME',
      dataIndex: 'organizationName',
      key: 'organizationName',
      sorter: true,
      sortOrder:
        sortedInfo?.columnKey === 'organizationName' && sortedInfo?.order
    },
    {
      title: 'USERS',
      dataIndex: '',
      key: 'users',
      render: (text, record) => {
        return `${record?.userCount}/${record?.userLimit}`;
      }
    },
    {
      title: 'EMAIL ID',
      dataIndex: 'adminEmail',
      key: 'adminEmail',
      sorter: true,
      sortOrder: sortedInfo?.columnKey === 'adminEmail' && sortedInfo?.order
    },
    {
      title: 'MOBILE NO.',
      dataIndex: 'phoneNumber',
      key: 'mobileNumber'
    },
    {
      title: 'VALID TILL',
      dataIndex: '',
      key: 'validTill',
      render: (validity, record) => {
        return moment(`${record?.subscription?.validTill}`).format(
          defaultDateFormat
        );
      }
    },
    {
      title: 'STATUS',
      dataIndex: '',
      key: 'status',
      render: (status, record) => {
        return <ChangeStatus record={record} />;
      }
    },
    {
      title: '',
      key: 'Action',
      render: (id, record) => {
        return (
          <div className="d-flex">
            <Button
              shape="round"
              icon={<EditButton />}
              className="edit-button pointer"
              onClick={(e) => {
                e.stopPropagation();
                history.push(`${ROUTES.ORGANIZATION}/edit/${record.id}`);
              }}
            />
            <Button shape="round" icon={<ActiveButtons record={record} />} />
          </div>
        );
      }
    }
  ];

  return (
    <div>
      {showModal && (
        <DeleteModal
          showModal={showModal}
          setShowModal={setShowModal}
          title={title}
          updateTenant={updateTenant}
          mutationId={mutationId}
          setMutationId={setMutationId}
          isDisabled={isDisabled}
          fetchOrganizationData={fetchOrganizationData}
          name="Organization"
          subtitle="Organization"
        />
      )}
      <Portal portalId="add-search-organization">
        <div className="organization-search">
          <SearchComponent
            id="search-container-id"
            name="Organizations"
            getData={organizationData}
          />
        </div>
      </Portal>
      {isDesktopViewport ? (
        <div className=" pointer">
          <CommonTable
            loadingData={
              loading ? (
                <div className="launch-loader">
                  <LoaderComponent />
                </div>
              ) : (
                ''
              )
            }
            columns={columns}
            data={newdata}
            onChange={handleTableChange}
            paginationConfig={paginationProp}
            onRow={(record) => {
              return {
                onClick: () => {
                  history.push(`${ROUTES.ORGANIZATION}/${record?.id}/details`);
                }
              };
            }}
            rowKey={(obj) => obj.id}
          />
        </div>
      ) : (
        <div className="organization-card pointer" onScroll={handleScroll}>
          {newdata?.map((data) => {
            return (
              <div
                key={data?.id}
                onClick={() => {
                  history.push(`${ROUTES.ORGANIZATION}/${data?.id}/details`);
                }}
              >
                <CommonCard>
                  <div className="common-card">
                    <div className="card-header">
                      <h1>{data?.organizationName}</h1>
                      <span>
                        <ChangeStatus record={data} />
                      </span>
                    </div>
                    <div className="card-content">
                      <br />
                      <p className="break-word">{data?.adminEmail}</p>
                      <p>{data?.phoneNumber}</p>
                      <p>Users: {`${data?.userCount}/ ${data?.userLimit}`}</p>
                      <p className="d-flex justify-between align-center">
                        <span>
                          Valid Till:
                          {moment(`${data?.subscription?.validTill}`).format(
                            defaultDateFormat
                          )}
                        </span>
                        <span>
                          <Button
                            shape="round"
                            icon={<EditButton />}
                            className="edit-button pointer"
                            onClick={(e) => {
                              e.stopPropagation();
                              history.push(
                                `${ROUTES.ORGANIZATION}/edit/${data?.id}`
                              );
                            }}
                          />
                          <Button
                            shape="round"
                            icon={<ActiveButtons record={data} />}
                          />
                        </span>
                      </p>
                    </div>
                  </div>
                </CommonCard>
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};

export default OrganizationTable;
