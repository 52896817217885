import { useQuery } from '@apollo/client';
import { Button, DatePicker, Form, Input, InputNumber, Select } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import { get, nth, values } from 'lodash';
import moment from 'moment';
import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { Calendar, ForwardArrow, Globe } from '../../../assets/svg';
import {
  ACCESSTYPE_KEYS,
  defaultDateFormat,
  REGEX,
  ROUTES
} from '../../../common/constants';
import { formValidatorRules } from '../../../common/utils';
import Portal from '../../../components/Portal';
import { GET_PLANS } from '../graphql/Queries';

const OrganizationForm = ({ mutation, organizationData, id, mutating }) => {
  const history = useHistory();
  const [form] = useForm();
  const { pathname } = useLocation();
  const { data } = useQuery(GET_PLANS);
  const fetchData = nth(organizationData, 0);
  const { required, email } = formValidatorRules;
  const onFinish = (e) => {
    const {
      organizationName,
      ownerName,
      phoneNumber,
      access,
      userLimit,
      adminEmail,
      planId,
      validTill
    } = e;

    const input = pathname.includes('edit')
      ? {
          planId: Number(planId),
          organizationName,
          ownerName,
          phoneNumber,
          access,
          validTill,
          userLimit: Number(userLimit)
        }
      : {
          adminEmail,
          planId: Number(planId),
          organizationName,
          ownerName,
          phoneNumber,
          access,
          validTill,
          userLimit: Number(userLimit)
        };

    mutation({
      variables: {
        id: id || null,
        data: input
      }
    })
      .then((response) => {
        const orgData = get(response, 'data.createTenant');
        if (orgData) {
          history.push(ROUTES.ORGANIZATION);
        }
      })
      .catch((err) => {
        // eslint-disable-next-line no-console
        console.log(err);
      });
  };

  const disabledDate = (current) => {
    // Can not select days before today and today
    return current && current <= moment().endOf('days');
  };

  return (
    <div className="form-content">
      <Portal portalId="header-left-content">
        <h1>
          <div className="d-flex">
            <Globe
              onClick={() => history.push(ROUTES.ORGANIZATION)}
              className="pointer"
            />
            <ForwardArrow />
          </div>
          {pathname.includes('edit') ? 'Edit Organization' : 'Add Organization'}
        </h1>
      </Portal>
      <div className="organization-form">
        <div className="add-organization-form">
          <div className="add-organization-content">
            <Form
              onFinish={onFinish}
              layout="vertical"
              form={form}
              initialValues={
                organizationData?.length > 0
                  ? {
                      ...fetchData,
                      planId: fetchData?.subscription?.planObj?.id,
                      validTill: moment(
                        `${fetchData?.subscription?.validTill}`
                      ),
                      name: fetchData?.subscription?.planObj?.name,
                      access: fetchData?.access
                    }
                  : ''
              }
            >
              <div>
                <h3>Organization Details</h3>
                <Form.Item
                  name="organizationName"
                  label="Organization Name"
                  rules={[required]}
                >
                  <Input placeholder="Name of Organization" maxLength={100} />
                </Form.Item>
              </div>
              <div className="owner-details">
                <h3 className="mt-20">Owner Details</h3>

                <Form.Item
                  name="adminEmail"
                  label="System Admin Email"
                  rules={[required, email]}
                >
                  <Input
                    placeholder="Enter Email"
                    disabled={pathname.includes('edit')}
                    maxLength={254}
                  />
                </Form.Item>

                <Form.Item
                  name="ownerName"
                  label="System Admin Name"
                  rules={[required]}
                >
                  <Input placeholder="Enter Owner Name" maxLength={100} />
                </Form.Item>

                <Form.Item
                  name="phoneNumber"
                  label="System Admin Mobile
                  Number"
                  rules={[
                    required,
                    () => ({
                      validator(rule, value) {
                        if (value) {
                          // eslint-disable-next-line no-param-reassign
                          value = value.split(' ').join('');
                          const numberPattern = REGEX.PHONE;
                          if (!numberPattern.test(value)) {
                            // eslint-disable-next-line prefer-promise-reject-errors
                            return Promise.reject(
                              'should be a valid phone number'
                            );
                          }
                        }
                        return Promise.resolve();
                      }
                    })
                  ]}
                >
                  <Input placeholder="Enter Mobile Number" />
                </Form.Item>
              </div>
              <div className="plan-details">
                <h3 className="mt-20">Plan Details</h3>

                <Form.Item name="planId" label="Plan" rules={[required]}>
                  <Select
                    name="planId"
                    placeholder="Select a Plan"
                    getPopupContainer={(triggerNode) =>
                      triggerNode?.parentElement
                    }
                  >
                    {data?.planList?.map((plan) => (
                      <Select.Option key={plan?.id}>{plan?.name}</Select.Option>
                    ))}
                  </Select>
                </Form.Item>

                <Form.Item
                  name="userLimit"
                  label="Number of users"
                  rules={[
                    required,
                    {
                      pattern: REGEX.NUMBER_GREATER_THAN_ZERO,
                      message: 'Should be greater than zero.'
                    }
                  ]}
                >
                  <InputNumber
                    placeholder="Enter Number of Users"
                    className="width-percent-100"
                    maxLength={20}
                  />
                </Form.Item>

                <Form.Item
                  name="validTill"
                  label="Valid Till"
                  rules={[required]}
                >
                  <DatePicker
                    format={defaultDateFormat}
                    disabledDate={disabledDate}
                    suffixIcon={<Calendar />}
                    placeholder="Select Date"
                  />
                </Form.Item>

                <Form.Item name="access" label="Access Type" rules={[required]}>
                  <Select
                    name="access"
                    placeholder="Select Access Type"
                    allowClear
                    mode="multiple"
                    getPopupContainer={(triggerNode) =>
                      triggerNode?.parentElement
                    }
                  >
                    {values(ACCESSTYPE_KEYS).map((Access) => (
                      <Select.Option key={Access} value={Access}>
                        {Access}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </div>
              <div className="form-buttons">
                <Button
                  shape="round"
                  className="cancel-button"
                  onClick={() => history.push(ROUTES.ORGANIZATION)}
                >
                  Cancel
                </Button>
                <Button
                  shape="round"
                  className="save-button"
                  loading={mutating}
                  htmlType="submit"
                >
                  Save
                </Button>
              </div>
            </Form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OrganizationForm;
