import { useMutation } from '@apollo/client';
import { Button, Form, Input, Modal, Select } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import { includes, keys, values } from 'lodash';
import React from 'react';
import { useParams } from 'react-router-dom';
import { REGEX, ROLES, ROLES_KEYS } from '../../common/constants';
import { formValidatorRules, titleCase } from '../../common/utils';
import {
  CREATE_ORGANIZATION_USERS,
  UPDATE_ORGANIZATION_USERS
} from './graphql/Mutations';

const AddEditUserModal = ({
  showModal = false,
  setShowModal,
  userData,
  fetchUsers
}) => {
  const [form] = useForm();
  const { required, email } = formValidatorRules;
  const { id } = useParams();
  const [createUser, { loading: createLoading }] = useMutation(
    CREATE_ORGANIZATION_USERS,
    {
      onError() {},
      onCompleted: () => {
        setShowModal(false);
        fetchUsers();
      }
    }
  );

  const [updateUsers, { loading: updateLoading }] = useMutation(
    UPDATE_ORGANIZATION_USERS,
    {
      onError() {},
      onCompleted: () => {
        setShowModal(false);
        fetchUsers();
      }
    }
  );

  const onFinish = (e) => {
    // eslint-disable-next-line no-shadow
    const { name, roles, phoneNo, email } = e;
    if (!userData) {
      createUser({
        variables: {
          data: {
            name,
            email,
            phoneNo,
            tenantId: Number(id)
          }
        }
      });
    } else {
      updateUsers({
        variables: {
          id: userData?.id,
          data: {
            tenantId: Number(id),
            roles
          }
        }
      });
    }
  };

  const handleCancel = () => {
    setShowModal(false);
  };

  const handleRoles = () => {
    if (userData) {
      return values(ROLES_KEYS).map((roles) => (
        <Select.Option key={roles} value={roles}>
          {titleCase(roles)}
        </Select.Option>
      ));
    }
    return (
      <Select.Option
        key={ROLES_KEYS.SYSTEM_ADMIN}
        value={ROLES_KEYS.SYSTEM_ADMIN}
      >
        {titleCase(ROLES.SYSTEM_ADMIN)}
      </Select.Option>
    );
  };

  return (
    <>
      <Modal visible={showModal} centered footer={null} closable={false}>
        <h2>User Details</h2>
        <Form
          form={form}
          layout="vertical"
          onFinish={onFinish}
          initialValues={
            userData ? { name: userData?.name, roles: userData?.roles } : {}
          }
        >
          <Form.Item name="name" label="Name" rules={[required]}>
            <Input allowClear placeholder="Enter Name" disabled={!!userData} />
          </Form.Item>
          <Form.Item label="Role" name="roles" rules={[required]}>
            <Select name="access" placeholder="Select a Role" allowClear>
              {handleRoles()}
              {!includes(keys(ROLES_KEYS), userData?.roles?.[0]) && (
                <Select.Option
                  key="other-roles"
                  value={userData?.roles?.[0]}
                  disabled
                >
                  {titleCase(userData?.roles?.[0])}
                </Select.Option>
              )}
            </Select>
          </Form.Item>
          {!userData && (
            <>
              <Form.Item label="Email" name="email" rules={[required, email]}>
                <Input allowClear placeholder="Enter Email" />
              </Form.Item>
              <Form.Item
                label="Mobile Number"
                name="phoneNo"
                rules={[
                  required,
                  () => ({
                    validator(rule, value) {
                      if (value) {
                        // eslint-disable-next-line no-param-reassign
                        value = value.split(' ').join('');
                        const numberPattern = REGEX.PHONE;
                        if (!numberPattern.test(value)) {
                          // eslint-disable-next-line prefer-promise-reject-errors
                          return Promise.reject(
                            'should be a valid phone number'
                          );
                        }
                      }
                      return Promise.resolve();
                    }
                  })
                ]}
              >
                <Input allowClear placeholder="Enter Mobile Number" />
              </Form.Item>
            </>
          )}
          <div className="form-buttons">
            <Button
              shape="round"
              className="cancel-button"
              onClick={handleCancel}
            >
              Cancel
            </Button>
            <Button
              shape="round"
              className="save-button"
              htmlType="submit"
              loading={createLoading || updateLoading}
            >
              Save
            </Button>
          </div>
        </Form>
      </Modal>
    </>
  );
};

export default AddEditUserModal;
