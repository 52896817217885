/* ROUTERS  */
export const ROUTES = {
  MAIN: '/',
  ORGANIZATION: '/organization',
  USERS: '/users',
  LOGOUT: '/logout',
  LOGIN: '/login',
  AUTHENTICATION: '/authentication',
  FORGOT: '/forgot-password',
  CHANGE: '/change-password',
  RESET: '/reset-password',
  VERIFY: '/verify'
};

/*  Modules */
export const MODULES = {
  ORGANIZATIONS: 'Organizations',
  USERS: 'Users'
};

/* Authentication */
export const TOKEN = 'TOKEN';
export const REFRESH_TOKEN = 'REFRESH_TOKEN';
export const USER = 'USER';
export const TIMEOUT_TIME_MS = 3000;
export const POLLING_INTERVAL = 15000;
export const PING_RESOURCE = `/logo192.png`;

export const ACCESSTYPE = {
  INSTRUCTION: 'Instruction',
  INSPECTION: 'Inspection'
};

export const ACCESSTYPE_KEYS = {
  INSTRUCTION: 'INSTRUCTION',
  INSPECTION: 'INSPECTION'
};

export const ROLES = {
  SYSTEM_ADMIN: 'SYSTEM ADMIN',
  USER: 'USER'
};
export const ROLES_KEYS = {
  SYSTEM_ADMIN: 'SYSTEM_ADMIN',
  USER: 'USER'
};
export const ALL_ROLES_KEYS = {
  SYSTEM_ADMIN: 'SYSTEM_ADMIN',
  USER: 'USER',
  EXTERNAL_AUDITOR: 'EXTERNAL_AUDITOR',
  CHECKLIST_MAKER: 'CHECKLIST_MAKER'
};

export const SCROLL_CONST = 5;

export const BREAKPOINTS = {
  mobile: 550,
  tablet: 750,
  desktop: 1000,
  hd: 1200
};

/* Date and time */
export const defaultDateFormat = 'DD/MM/YYYY';

export const REGEX = {
  NAME: /^[a-z ,.'-]+$/i,
  ZIPCODE: /^[0-9]{5,6}$/,
  CITY: /^[a-zA-Z]+(?:[\s-][a-zA-Z]+)*$/,
  WEB_URL: /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/gi,
  PASSWORD: /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,16}$/,
  PHONE: /^[+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,6}$/,
  EMAIL: /^[a-z0-9.]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/,
  AMOUNT: /^\d+$|^\d+\.\d*$/,
  OPTIONALNEGATIVEAMOUNT: /^[-]?\d+$|^[-]?\d+\.\d*$/,
  NUMBER: /^\d+$/,
  NUMBER_GREATER_THAN_ZERO: /^[1-9]\d*$/
};
