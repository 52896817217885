/* eslint-disable no-undef */
import { useQuery } from '@apollo/client';
import { Tabs } from 'antd';
import { nth, parseInt } from 'lodash';
import React, { useEffect, useState } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { ForwardArrow, Globe } from '../../../../assets/svg';
import { ROUTES } from '../../../../common/constants';
import Portal from '../../../../components/Portal';
import { GET_ORGANIZATIONS } from '../../graphql/Queries';
import Details from './pages/Details';
import Users from './pages/Users';

const OrganizationDetails = () => {
  const { id } = useParams();
  const { TabPane } = Tabs;

  const { pathname } = useLocation();
  const history = useHistory();
  const [activeKey, setActiveKey] = useState('details');

  const { data } = useQuery(GET_ORGANIZATIONS, {
    variables: {
      filter: { tenantId: parseInt(id) },
      sortBy: { field: 'isDisabled', order: 'ASC' }
    },
    fetchPolicy: 'network-only'
  });
  const fetchData = nth(data?.tenantList?.data, 0);

  useEffect(() => {
    if (pathname?.includes('details')) {
      setActiveKey('details');
    } else {
      setActiveKey('users');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <div className="details">
        <Portal portalId="header-left-content">
          <h1>
            <div className="d-flex">
              <Globe
                onClick={() => history.push(ROUTES.ORGANIZATION)}
                className="pointer"
              />
              <ForwardArrow />
            </div>
            {fetchData?.organizationName}
          </h1>
        </Portal>

        <div className="details-content">
          <Tabs
            activeKey={activeKey}
            onChange={(key) => {
              setActiveKey(key);
              history.push(`${ROUTES.ORGANIZATION}/${id}/${key}`);
            }}
          >
            <TabPane tab="Details" key="details" className="details-tab">
              <div className="details">
                <Details data={data} />
              </div>
            </TabPane>
            <TabPane tab="Users" key="users" forceRender>
              <div className="users">
                <Users id={id} />
              </div>
            </TabPane>
          </Tabs>
        </div>
      </div>
    </>
  );
};

export default OrganizationDetails;
