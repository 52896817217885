import { Layout } from 'antd';
import * as eva from 'eva-icons';
import React, { useEffect } from 'react';
import { useMedia } from 'react-use';
import { BREAKPOINTS } from '../common/constants';
import './App.css';
import AppHeader from './components/header/AppHeader';
import MobileHeader from './components/header/MobileHeader';
import ContentRoutes from './ContentRoutes';

const { Content, Footer } = Layout;

const App = () => {
  useEffect(() => {
    eva.replace();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const isDesktopViewport = useMedia(`(min-width: ${BREAKPOINTS.desktop}px)`);
  return (
    <Layout className="gx-app-layout">
      {!isDesktopViewport && <MobileHeader />}
      <Layout>
        <AppHeader />
        <Content className="gx-layout-content">
          <div className="gx-main-content-wrapper">
            <ContentRoutes />
          </div>
          <Footer className="app-footer">
            <div>digiQC © {new Date().getFullYear()}</div>
            <div className="app-footer-product">
              <span>Product by</span>
              <span className="app-footer-product-title">
                <a
                  href="https://spaceify.in/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  SPACEIFY
                </a>
              </span>
            </div>
          </Footer>
        </Content>
      </Layout>
    </Layout>
  );
};

export default App;
