/* eslint-disable no-nested-ternary */
import { Descriptions, Tag } from 'antd';
import { nth } from 'lodash';
import moment from 'moment';
import React from 'react';
import { useMedia } from 'react-use';
import {
  BREAKPOINTS,
  defaultDateFormat
} from '../../../../../common/constants';

const Details = ({ data }) => {
  const isDesktopViewport = useMedia(`(min-width: ${BREAKPOINTS.desktop}px)`);
  const fetchData = nth(data?.tenantList?.data, 0);
  return (
    <div>
      <div className="details-content-organization">
        <h1>Organization Details</h1>
        <Descriptions
          layout="vertical"
          column={isDesktopViewport ? 2 : 1}
          className="details-owner-description"
        >
          <Descriptions.Item label="Owner Name">
            {fetchData?.ownerName}
          </Descriptions.Item>
          <Descriptions.Item label="Email">
            {fetchData?.adminEmail}
          </Descriptions.Item>
          <Descriptions.Item label="Organization Name">
            {fetchData?.organizationName}
          </Descriptions.Item>
          <Descriptions.Item label="Users">
            {`${fetchData?.userCount}/ ${fetchData?.userLimit}`}
          </Descriptions.Item>
        </Descriptions>
      </div>
      <div className="details-content-subscription">
        <h1>Subscription Details</h1>
        <Descriptions
          layout="vertical"
          column={isDesktopViewport ? 2 : 1}
          className="details-owner-description"
        >
          <Descriptions.Item label="Current Plan">
            {fetchData?.subscription?.planObj?.name}
          </Descriptions.Item>
          <Descriptions.Item label="Number of Days">
            {fetchData?.subscription?.validTill ? (
              moment(fetchData?.subscription?.validTill).diff(
                moment(),
                'days'
              ) > 0 ? (
                moment(fetchData?.subscription?.validTill).diff(
                  moment(),
                  'days'
                )
              ) : (
                <Tag color="error">Expired</Tag>
              )
            ) : (
              '-'
            )}
          </Descriptions.Item>
          <Descriptions.Item label="Grace Days">
            {fetchData?.subscription?.graceDays}
          </Descriptions.Item>
          <Descriptions.Item label="Valid Till">
            {fetchData?.subscription?.validTill
              ? moment(fetchData?.subscription?.validTill).format(
                  defaultDateFormat
                )
              : '-'}
          </Descriptions.Item>
        </Descriptions>
      </div>
    </div>
  );
};

export default Details;
