import { Layout, Menu } from 'antd';
import React from 'react';
import { Link, useHistory, withRouter } from 'react-router-dom';
import { useMedia } from 'react-use';
import { LoginLogo } from '../../../assets/svg/login';
import { Account } from '../../../assets/svg/sider';
import { BREAKPOINTS, MODULES, ROUTES } from '../../../common/constants';

const { Header } = Layout;
const { SubMenu } = Menu;

const AppHeader = ({ location: { pathname } }) => {
  const history = useHistory();
  const onMenuSelect = (e) => {
    history.push(e.key);
  };
  const isDesktopViewport = useMedia(`(min-width: ${BREAKPOINTS.desktop}px)`);
  return (
    <>
      {isDesktopViewport && (
        <Header>
          <div className="gx-search-bar gx-d-none gx-d-lg-block gx-lt-icon-search-bar-lg d-flex width-percent-100">
            <div className="header-logo">
              <Link to={ROUTES.MAIN} className="gx-site-logo mr-25">
                <LoginLogo />
              </Link>
            </div>
            <div className="header custom-header d-flex align-center justify-center width-percent-100">
              <Menu
                mode="horizontal"
                selectedKeys={[`/${pathname.split('/')[1]}`]}
                onSelect={onMenuSelect}
                onClick={onMenuSelect}
              >
                <Menu.Item
                  key={ROUTES.ORGANIZATION}
                  className="d-flex align-center"
                >
                  <span>{MODULES.ORGANIZATIONS}</span>
                </Menu.Item>
                <Menu.Item key={ROUTES.USERS} className="d-flex align-center">
                  <span>{MODULES.USERS}</span>
                </Menu.Item>
              </Menu>
            </div>
          </div>
          <div className="header-right">
            <Menu mode="horizontal">
              <SubMenu icon={<Account />} title="Account">
                <Menu.Item onClick={() => history.push(ROUTES.CHANGE)}>
                  Change Password
                </Menu.Item>
                <Menu.Item
                  onClick={() => {
                    history.push(ROUTES.LOGOUT);
                  }}
                >
                  Logout
                </Menu.Item>
              </SubMenu>
            </Menu>
          </div>
        </Header>
      )}
      <Header className={`title-bar ${isDesktopViewport ? 'mt-15' : ''}`}>
        <div className="header-portal-wrapper d-flex justify-between align-center fill-width">
          <div id="header-left-content" className="d-flex align-center">
            <div id="header-left-content-inside" className="d-flex" />
          </div>
          <div className="d-flex justify-between">
            <div id="add-search-organization" />
            <div id="header-right-content" />
          </div>
        </div>
      </Header>
    </>
  );
};
export default withRouter(AppHeader);
