import { useMutation } from '@apollo/client';
import { Button, Form, Input, message, Spin } from 'antd';
import React, { useContext } from 'react';
import { AppContext } from '../../AppContext';
import { BackArrow } from '../../assets/svg';
import { Password } from '../../assets/svg/login';
import { REGEX, ROUTES } from '../../common/constants';
import { formValidatorRules } from '../../common/utils';
import Portal from '../../components/Portal';
import history from '../../historyData';
import { CHANGE_PASSWORD } from './graphql/Mutations';

const { required } = formValidatorRules;

const ChangePassword = () => {
  const { dispatch } = useContext(AppContext);
  const [changePassword, { loading }] = useMutation(CHANGE_PASSWORD, {
    onError() {},
    onCompleted() {
      dispatch({ type: 'LOGOUT' });
      history.push(ROUTES.LOGIN);
    }
  });
  const onFinish = (e) => {
    const { oldPassword, newPassword, confirmPassword } = e;
    if (newPassword !== confirmPassword) {
      message.error('Password does not match');
      return 0;
    }
    if (oldPassword === newPassword) {
      message.error('Old Password cannot be same as new password!');
      return 0;
    }
    changePassword({ variables: { data: { oldPassword, newPassword } } });
  };

  return (
    <div>
      <Portal portalId="header-left-content">
        <h1>
          <BackArrow
            className="header-left-content-h1-svg pointer"
            onClick={() => history.goBack()}
          />
          Change Password
        </h1>
      </Portal>
      <div className="change-password-content">
        <div className="change-password-inside-content">
          <div className="gx-mb-4">
            <h1>Change your password</h1>
          </div>
          <Spin spinning={false}>
            <Form
              name="change-password"
              layout="vertical"
              initialValues={{ remember: true }}
              onFinish={onFinish}
            >
              <Form.Item
                name="oldPassword"
                label="Old Password"
                rules={[required]}
              >
                <Input.Password
                  addonBefore={<Password />}
                  placeholder="At least 8 characters long"
                />
              </Form.Item>
              <Form.Item
                name="newPassword"
                label="New Password"
                rules={[
                  required,
                  () => ({
                    validator(rule, value) {
                      if (value) {
                        // eslint-disable-next-line no-param-reassign
                        value = value.split(' ').join('');
                        const numberPattern = REGEX.PASSWORD;
                        if (!numberPattern.test(value)) {
                          // eslint-disable-next-line prefer-promise-reject-errors
                          return Promise.reject(
                            'Password should contain min 8 letter, with at least a symbol, upper and lower case letters and a number!'
                          );
                        }
                      }
                      return Promise.resolve();
                    }
                  })
                ]}
              >
                <Input.Password
                  addonBefore={<Password />}
                  placeholder="At least 8 characters long"
                />
              </Form.Item>
              <Form.Item
                name="confirmPassword"
                label="Confirm Password"
                rules={[
                  required,
                  () => ({
                    validator(rule, value) {
                      if (value) {
                        // eslint-disable-next-line no-param-reassign
                        value = value.split(' ').join('');
                        const numberPattern = REGEX.PASSWORD;
                        if (!numberPattern.test(value)) {
                          // eslint-disable-next-line prefer-promise-reject-errors
                          return Promise.reject(
                            'Password should contain min 8 letter, with at least a symbol, upper and lower case letters and a number!'
                          );
                        }
                      }
                      return Promise.resolve();
                    }
                  })
                ]}
              >
                <Input.Password
                  addonBefore={<Password />}
                  placeholder="At least 8 characters long"
                />
              </Form.Item>
              <div className="form-buttons d-flex">
                <Form.Item>
                  <Button
                    shape="round"
                    className="cancel-button"
                    onClick={() => {
                      history.push(ROUTES.LOGIN);
                    }}
                  >
                    Cancel
                  </Button>
                </Form.Item>
                <Form.Item>
                  <Button
                    loading={loading}
                    type="primary"
                    shape="round"
                    className="save-button"
                    htmlType="submit"
                  >
                    Save
                  </Button>
                </Form.Item>
              </div>
            </Form>
          </Spin>
        </div>
      </div>
    </div>
  );
};

export default ChangePassword;
