import { useLazyQuery } from '@apollo/client';
import React, { useEffect, useState } from 'react';
import { useMedia } from 'react-use';
import { BREAKPOINTS } from '../../../common/constants';
import CommonCard from '../../../components/CommonCard';
import CommonTable from '../../../components/CommonTable';
import Portal from '../../../components/Portal';
import SearchComponent from '../../../components/SearchComponent';
import { GET_USERS } from '../graphql/Queries';

const UserTable = () => {
  const initialUserFilter = {
    field: 'id',
    order: 'ASC'
  };
  const skip = 0;
  const limit = 5;

  const [userFilter, setUserFilter] = useState(initialUserFilter);
  const [sortedInfo, setSortedInfo] = useState({});
  const isDesktopViewport = useMedia(`(min-width: ${BREAKPOINTS.desktop}px)`);

  const [fetchUserData, { loading, data }] = useLazyQuery(GET_USERS, {
    fetchPolicy: 'network-only',
    onError() {}
  });

  useEffect(() => {
    fetchUserData({
      variables: {
        filter: { skip: skip, sortBy: userFilter, search: '', limit: limit }
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const getData = async (value) => {
    fetchUserData({
      variables: {
        filter: {
          skip: 0,
          search: value
        }
      }
    });
  };

  const handleTableChange = (x, y, sorter) => {
    setSortedInfo(sorter);
    setUserFilter({ ...userFilter, skip: skip });
    if (sorter?.column) {
      setUserFilter({
        field: sorter.field,
        order: sorter.order === 'ascend' ? 'ASC' : 'DESC'
      });
      fetchUserData({
        variables: {
          filter: {
            skip,
            sortBy: {
              field: sorter.field,
              order: sorter.order === 'ascend' ? 'ASC' : 'DESC'
            }
          }
        }
      });
    } else {
      setUserFilter({ field: 'id', order: 'ASC' });
      fetchUserData({
        variables: {
          filter: {
            skip,
            limit,
            sortBy: { field: 'id', order: 'ASC' }
          }
        }
      });
    }
  };

  const columns = [
    {
      title: 'ID',
      dataIndex: '',
      key: 'id',
      render: (text, record, index) => {
        return <>{index + 1}</>;
      },
      width: '15%'
    },

    {
      title: 'USERNAME',
      dataIndex: 'name',
      key: 'name',
      sorter: true,
      sortOrder: sortedInfo?.columnKey === 'name' && sortedInfo?.order,
      width: '30%'
    },
    {
      title: 'EMAIL',
      dataIndex: 'email',
      key: 'email',
      sorter: true,
      sortOrder: sortedInfo?.columnKey === 'email' && sortedInfo?.order,
      width: '60%'
    }
  ];
  return (
    <>
      <Portal portalId="header-right-content">
        <div className="users-search">
          <SearchComponent
            id="search-container-id"
            name="Users"
            getData={getData}
          />
        </div>
      </Portal>
      {isDesktopViewport ? (
        <CommonTable
          loadingData={loading}
          columns={columns}
          data={data?.superAdminList?.data || []}
          onChange={handleTableChange}
          rowKey={(obj) => obj.id}
        />
      ) : (
        <div className="users-card pointer">
          {data?.superAdminList?.data?.map((userData) => {
            return (
              <div key={userData?.id}>
                <CommonCard>
                  <div>
                    <div className="card-header">
                      <h1>{userData?.name}</h1>
                    </div>
                    <div className="card-content">
                      <p>{userData?.email}</p>
                    </div>
                  </div>
                </CommonCard>
              </div>
            );
          })}
        </div>
      )}
    </>
  );
};

export default UserTable;
